<template>
  <b-container id="saml-connection-view" class="mt-4" fluid>
    <b-row>
      <b-col cols="12">
        <h3>SAML Connection</h3>
        <router-link :to="{ name: 'SamlCoreConnectionList' }" class="d-flex justify-content-left align-items-center">
          <b-icon icon="arrow-left" aria-label="Back to connection list"/>
          Back to List
        </router-link>
      </b-col>
    </b-row>
    <div v-if="loading" class="row mt-4">
      <div class="col-12 d-flex justify-content-center">
        <div class="spinner spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
    <template v-else>
      <b-row class="mt-4">
        <b-col>
          <b-form-group
            label="Connection Key"
            label-for="connection-key">
            <b-form-input
              id="connection-key" type="text" :disabled="true"
              :value="connection.key"/>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Partner Key"
            label-for="partner-key">
            <b-form-input
              id="partner-key" type="text" :disabled="true"
              :value="connection.core_partner_key"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card title="Configuration">
            <b-row>
              <b-col v-if="configuration" cols="3">
                <b-form-group
                  label="Configuration"
                  label-for="core-configuration">
                  <b-form-input
                    id="core-configuration" type="text" :disabled="true"
                    :value="configuration"/>
                </b-form-group>
                <b-button variant="light"
                          :to="{ name: 'SamlCoreConfigurationView', params: { uuid: connection.core_configuration_uuid } }">
                  <b-icon font-scale="1" icon="eye" aria-label="View connection"></b-icon>
                </b-button>
              </b-col>
              <b-col v-else>
                <b-form-group
                  label="Public Key"
                  label-for="connection-public-certificate">
                  <b-form-textarea
                    id="connection-public-certificate" :disabled="true"
                    rows="10"
                    :value="connection.public_key"/>
                </b-form-group>

                <b-button variant="light" size="sm" class="mb-2" @click="copyToClipboard(connection.public_key)">
                  <b-icon icon="clipboard" aria-label="Copy to clipboard"/>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <b-card title="IdP Metadata">
            <b-row>
              <b-col cols="3" v-if="!!connection.metadata_url">
                <b-form-group
                  label="URL"
                  label-for="metadata-url">
                  <b-form-input
                    id="metadata-url" type="text" :disabled="true"
                    :value="connection.metadata_url"/>
                </b-form-group>

                <b-button variant="light" size="sm" class="mb-2" @click="copyToClipboard(connection.metadata_url)">
                  <b-icon icon="clipboard" aria-label="Copy to clipboard"/>
                </b-button>
              </b-col>
              <b-col v-else>
                <b-form-group
                  label="File"
                  label-for="metadata-content">
                  <b-form-textarea
                    id="metadata-content" :disabled="true"
                    rows="10"
                    :value="connection.metadata_content"/>
                </b-form-group>

                <b-button variant="light" size="sm" class="mb-2" @click="copyToClipboard(connection.metadata_content)">
                  <b-icon icon="clipboard" aria-label="Copy to clipboard"/>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </b-container>
</template>

<script>
import Vue from 'vue';
import { get as getConnection } from '@/api/saml/core/connections';
import { get as getConfiguration } from '@/api/saml/core/configurations';

export default {
  name: 'SamlCoreConnectionView',
  beforeMount() {
    this.loading = true;

    getConnection(this.$route.params.key)
      .then(res => {
        const connection = res;

        if (connection?.core_configuration_uuid) {
          return getConfiguration(connection.core_configuration_uuid)
            .then(configuration => {
              connection.core_configuration_name = configuration.name;
              return connection;
            })
            .catch(err => {
              Vue.prototype.$noty.error(`Failed to get connection: ${err}`);
              return connection;
            });
        }

        return Promise.resolve(connection);
      })
      .then(connection => {
        this.connection = connection;
      })
      .catch(err => {
        Vue.prototype.$noty.error(`Failed to get connection: ${err}`);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  data() {
    return {
      connection: {},
      loading: false,
    };
  },
  computed: {
    configuration() {
      return this.connection?.core_configuration_name || this.connection?.core_configuration_uuid;
    },
  },
  methods: {
    copyToClipboard(value) {
      if (!navigator || !navigator.clipboard) {
        this.$noty.error('Browser feature unsupported.');
        return;
      }

      navigator.clipboard
        .writeText(value)
        .then(() => this.$noty.success('Copied successfully.'))
        .catch(e => this.$noty.error(e.message || 'Unknown error.'));
    },
  },
};
</script>
